.textContainer {
    display:flex; 
    flex-direction: column;
}

.centeredText {
    display:flex; 
    flex-direction: column;
    justify-content: center;
}

.centeredImage {
    display: flex; 
    align-items: center; 
    justify-content: center;
}