@charset "utf-8";

// Import a Google Font
@import url('https://fonts.googleapis.com/css?family=Orbitron:400,700');

// Set your brand colors
$purple: #8A4D76;
$red: rgb(185, 0, 0);
$brown: #757763;
$blue: #0021ff; 
$beige-light: #D0D1CD;


// Update Bulma's global variables
$family-sans-serif: "Orbitron", sans-serif;
// $grey-dark: $brown;
// $grey-light: $beige-light;
$primary: $blue;
$link: $red;

// Update some of Bulma's component variables
// $body-background-color: rgb(0, 0, 0);
// $control-border-width: 2px;



@import "../node_modules/bulma/bulma.sass";